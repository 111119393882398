import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/new-base-select'
// 个人担保添加查询
export const formConfig = (content) => {
  return [
    {
      label: '姓名：',
      prop: 'personnerName',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.personnerName = data.replace(/[0-9]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '身份证：',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.idCard = data.replace(/[\u4E00-\u9FA5]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '电话号码：',
      prop: 'mobilePhoneNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 11
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.mobilePhoneNo = data.replace(/[\u4E00-\u9FA5]|[a-zA-Z]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 个人担保表格
export const tableConfig = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },

    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
export const newColumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
// 个人担保关系子表格
export const relationshipTableConfig = (content) => {
  // const renderHeader = function (h, { column, $index }) {
  //   return [
  //     h(
  //       'el-checkbox', {
  //         props: {
  //           value: content.checkAll,
  //           indeterminate: content.isIndeterminate
  //         },
  //         on: {
  //           change: content.updateAllSelected // 选中事件
  //         }
  //       }
  //     )

  //   ]
  // }
  return [
    // {
    //   type: 'selection',
    //   width: '55px'
    // },
    {
      prop: 'implementSituation',
      // 'render-header': renderHeader,
      width: '120px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    // {
    //   label: '职位',
    //   prop: 'dutiesName'
    // },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增个人担保表格
export const addTableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '人员类别',
      prop: 'personTypeCode'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保关系子表格
export const addRelationTableConfig = () => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '关系',
      prop: 'contactType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增担保
export const enterpriseForm = (content) => {
  return [
    {
      label: '企业名称：',
      prop: 'enterpriseName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      },
      on: {
        input: data => {
          content.queryParas.enterpriseName = data.replace(/\s+/g, '')
        }
      }
    },
    {
      label: '统一社会信用代码：',
      prop: 'creditCode',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          content.queryParas.creditCode = data.replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 企业担保表格
export const enterpriseTable = () => {
  return [
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'enterpriseName'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode'
    }
  ]
}
// 新增补充合同信息
export const contractForm = (content) => {
  return [
    {
      label: '合同编码：',
      prop: 'contractCode',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      },
      on: {
        input: data => {
          content.queryParas.enterpriseName = data.replace(/\s+/g, '')
        }
      }
    },
    {
      label: '合同名称：',
      prop: 'contractName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50
      },
      on: {
        input: data => {
          content.queryParas.enterpriseName = data.replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 补充合同信息表格
export const contractTable = () => {
  return [
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '合同编码',
      prop: 'contractCode'
    },
    {
      label: '合同名称',
      prop: 'contractName'
    }
  ]
}

// 还款预览列表
export const viewList = (content) => {
  return [
    {
      label: '还款期数',
      prop: 'repayPeriods'
    },
    {
      label: '还款时间',
      prop: 'preRepayDate',
      formatter: row => {
        return formatDate(row.preRepayDate, 'YY-MM-DD')
      }
    },
    {
      label: '还款比例(%)',
      prop: 'preRepayRate',
      isHidden: content.FinRepayPlanDTO.repayType === '1'
    },
    {
      label: '还款本金(元)',
      prop: 'preRepayAmount',
      formatter: row => {
        return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0')
      }
    }
  ]
}
// 基础资料查询条件
export const businessFormConfig = (content) => {
  return [
    {
      label: '企业名称：',
      prop: 'enterpriseId',
      attrs: {
        placeholder: '请选择',
        selectedField: ['keyId', 'enterpriseName'],
        options: content.options,
        disabled: true
      },
      tag: BaseSelect
    },
    {
      label: '资料类型：',
      prop: 'configItemCode',
      attrs: {
        options: content.typeOption,
        placeholder: '请选择',
        selectOptions: ['id', 'name'],
        disabled: true
      },
      tag: BaseSelect
    }
  ]
}

// 选择企业资料列表
export const businessColumns = (content) => {
  return [
    {
      type: 'selection',
      width: '60px'
    },
    {
      label: '序号',
      prop: 'index',
      width: '60px'
    },
    {
      label: '资料类型',
      prop: 'configItemCode',
      minWidth: '150px',
      formatter: data => {
        const [obj] = content.typeOption.filter(item => item.id === data.configItemCode)
        return obj ? obj.name : ''
      }
    },
    {
      label: '上传时间',
      prop: 'uploadTime',
      minWidth: '150px',
      formatter: data => {
        return formatDate(data.uploadTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '上传人',
      prop: 'uploadUser',
      minWidth: '150px'
    },
    {
      label: '是否已经盖章',
      prop: 'signed',
      width: '120px',
      formatter: data => {
        return data.signed === 1 ? '是' : '否'
      },
      isHidden: !content.showSigned
    },
    {
      label: '附件',
      prop: 'attachment',
      minWidth: '250px'
    }
  ]
}
